<template>
  <div></div>
</template>

<script>
export default {
  data: function () {
    return {

    }
  },
  components: {

  },
  methods: {

  },
  mounted: function () {
    const state = this.$route.query.state
    this.$authModule.ApiCallback(state)
  }

}
</script>
<style>

</style>
